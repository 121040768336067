
const PagejsonLd =
{
  "@context": "http://www.schema.org",
  "@type": "Organization",
  "name": "GetBiz Solutions",
  "url": "https://getbiz.solutions/",
  "sameAs": [
    "https://www.facebook.com/getbizsolutions",
    "https://www.youtube.com/@getbiz_solutions",
    "https://www.pinterest.com/getbiz_solutions",
    "https://www.linkedin.com/company/getbiz-solutions",
    "https://www.instagram.com/getbiz_solutions",
    "https://x.com/Getbiz_Solution",
  ],
  "description": "Discover GetBiz Solutions expert services in web design, website development, and digital marketing. Let us boost your online presence and business success.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "537, 5 Square Point,",
    "postOfficeBoxNumber": "RANDER",
    "addressLocality": "Surat",
    "addressRegion": "Gujarat",
    "postalCode": "395005",
    "addressCountry": "India"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": "21.229726791381836",
    "longitude": "72.78727722167969"
  },
  "hasMap": "https://maps.app.goo.gl/Z9eH1vt8rjYeyHSz8",
  "openingHours": "Mo 08:00-21:00 Tu 08:00-21:00 We 08:00-21:00 Th 08:00-21:00 Fr 08:00-21:00 Sa 08:00-18:00"
};

  const BlogjsonLd = [
  {
    "@context": "http://www.schema.org",
    "@type": "Organization",
    "name": "GetBiz Solutions",
    "url": "https://getbiz.solutions/",
    "sameAs": [
      "https://www.facebook.com/getbizsolutions",
      "https://www.youtube.com/@getbiz_solutions",
      "https://www.pinterest.com/getbiz_solutions",
      "https://www.linkedin.com/company/getbiz-solutions",
      "https://www.instagram.com/getbiz_solutions",
      "https://x.com/Getbiz_Solution",
    ],
    "description": "Discover GetBiz Solutions expert services in web design, website development, and digital marketing. Let us boost your online presence and business success.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "537, 5 Square Point,",
      "postOfficeBoxNumber": "RANDER",
      "addressLocality": "Surat",
      "addressRegion": "Gujarat",
      "postalCode": "395005",
      "addressCountry": "India"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "21.229726791381836",
      "longitude": "72.78727722167969"
    },
    "hasMap": "https://maps.app.goo.gl/Z9eH1vt8rjYeyHSz8",
    "openingHours": "Mo 08:00-21:00 Tu 08:00-21:00 We 08:00-21:00 Th 08:00-21:00 Fr 08:00-21:00 Sa 08:00-18:00"
  }
];

export { PagejsonLd, BlogjsonLd };
