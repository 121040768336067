'use client'
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { PagejsonLd } from '@/components/common/LdjsonArray';

interface JsonLdProps {
  jsonLdData: string[];
  FaqArray: string[];
  ProductArray: string[];
}

const JsonLd: React.FC<JsonLdProps> = ({ jsonLdData, FaqArray }) => {
  // Ensure jsonLdData is an array
  const jsonLdArray = Array.isArray(jsonLdData) ? jsonLdData : [];
  const FaqsArray = Array.isArray(FaqArray) ? FaqArray : [];

  PagejsonLd
  // const FaqsArray = Array.isArray(FaqArray) ? FaqArray : [];
  // const ProductsArray = Array.isArray(ProductArray) ? ProductArray : [];

  const baseURL = "https://getbiz.solutions";

  const [breadcrumbJsonLd, setBreadcrumbJsonLd] = useState<string | null>(null);

  useEffect(() => {
    const generateBreadcrumbs = () => {
      const pathArray = window.location.pathname.split('/').filter((segment) => segment !== '');
      // const mappedPathArray = pathArray.map((segment) => {
      //   if (segment === 'services') return 'what-we-do';
      //   if (segment === 'web') return 'web-development';
      //   if (segment === 'mobile') return 'mobile-app-development';
      //   return segment;
      // });

      // Original URL
      const breadcrumbItems = pathArray.map((segment, index) => {
        const url = `${baseURL}/${pathArray.slice(0, index + 1).join('/')}`;

        // Replaced URL
        // const breadcrumbItems = mappedPathArray.map((segment, index) => {
        //   const url = `${baseURL}/${mappedPathArray.slice(0, index + 1).join('/')}`;
        return {
          "@type": "ListItem",
          "position": index + 2,
          "name": segment.charAt(0).toUpperCase() + segment.slice(1),
          "item": (index === 2) ? baseURL + window.location.pathname : url,
        };
      });

      const homeBreadcrumb = {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": baseURL + '/'
      };

      return [homeBreadcrumb, ...breadcrumbItems];
    };

    const breadcrumbListSchema = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": generateBreadcrumbs()
    };

    setBreadcrumbJsonLd(JSON.stringify(breadcrumbListSchema));
  }, []);
  return (
    <>
      <head>
        {/* {jsonLdArray.map((jsonLdObject, index) => (
          <script
            key={index}
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: jsonLdObject }}
          />
        ))} */}

        {/* Organization */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(PagejsonLd) ?? '' }}
        />

        {/* BreadcrumbList */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: breadcrumbJsonLd ?? '' }}
        />

         {/* Faq */}
         {FaqsArray && FaqsArray.length > 0 && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(FaqsArray) }}
          />
        )}

        {/* Product */}
        {/* {ProductsArray && ProductsArray.length > 0 && (
          < script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(ProductsArray) }}
          />
        )} */}

        {/* Old_From_seometadata.json_File Organization */}
        {jsonLdArray.map((jsonLdObject, index) => (
          <script
            key={index}
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdObject) }}
          />
        ))}

      </head>
    </>
  );
};

export default JsonLd;
